export default {
  light: {
    palette: {
      mode: 'light',
      primary: {
        main:
          process.env.REACT_APP_ENVIRONMENT_NAME === 'production'
            ? '#2CE080'
            : '#C8064E',
      },
      background: {
        default: '#f5f5f5',
      },
      secondary: {
        main: '#21314D',
      },
    },
    shape: {
      borderRadius: 12,
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main:
          process.env.REACT_APP_ENVIRONMENT_NAME === 'production'
            ? '#2CE080'
            : '#C8064E',
      },
      background: {
        default: '#0a0f17',
        paper: '#21314D',
      },
      secondary: {
        main: '#21314D',
      },
    },
    shape: {
      borderRadius: 12,
    },
  },
}
